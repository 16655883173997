<template>
    <div class="dialog-add-account" v-loading="loading">
      <div 
        class="dialog-add-account__title">
        {{ dialogTitle ? dialogTitle : '' }}
      </div>
  
      <div 
        class="dialog-add-account__desc"  
        v-html="$t(`get_business_xls.title${baActivePlatform.payload ?  `_${baActivePlatform.payload}` : ''}`)" 
      />
      <!-- <div 
        class="dialog-add-account__desc"  
        v-html="$t(`get_business_xls.title${baActivePlatform.platform  && baActivePlatform.google_upload ? '_google_upload': baActivePlatform.platform ?  `_${baActivePlatform.platform}` : ''}`)" 
      /> -->
      <!-- <div 
        class="dialog-add-account__desc"  
        v-html="$t('get_business_xls.title_all')" 
      /> -->
      <div class="dialog-add-account__data-picker">
        <span class="dialog-add-account__data-title">{{$t('get_business_xls.data_range')}}:</span>
        <div v-if="lang === 'ru'">
          <date-range-picker :key="lang" class="statistics__period-calendar" :class="{ 'statistics__period-calendar--open': isShow }"
          ref="picker" v-model="date" single-date-picker="range" :auto-apply="true" :always-show-calendars="true"
          :ranges="false" :locale-data="localeRU" @toggle="isShow = !isShow" opens="left">
          <template #input="picker">
            {{ fixDate(picker.startDate, picker.endDate) }}
          </template>
        </date-range-picker>
        </div>
        <div v-if="lang === 'en'">
          <date-range-picker :key="lang" class="statistics__period-calendar" :class="{ 'statistics__period-calendar--open': isShow }"
            ref="picker" v-model="date" single-date-picker="range" :auto-apply="true" :always-show-calendars="true"
            :ranges="false" :locale-data="localeEN" @toggle="isShow = !isShow" opens="left">
            <template #input="picker">
              {{ fixDate(picker.startDate, picker.endDate) }}
            </template>
          </date-range-picker>
        </div>
      </div>
      <div>
      </div>
      <div class="dialog-add-account__button-wrapper">
        <span class="error" v-if="dataError">{{ respErr }}</span>
        <PlatformSelector
          v-if="baActivePlatform.payload==='aar' || baActivePlatform.payload ==='ad' || baActivePlatform.payload==='saa' || baActivePlatform.payload === 'first-billing-ba'"
          :title="$t('selector_props.platform_type')" 
          :options="locale === 'USA'? platformTypes.en:platformTypes.ru" 
          @handleChange="getNewData" 
        />
        <ButtonElement :text="$t('button_props.send')" :disabled="!date.endDate && !date.startDate" @click-button="getAllUsersList" />
      </div>
  
    </div>
  </template>
    
  <script>
  import PlatformSelector from "@/components/sort/PlatformSelector"
  import formValidate from '../../mixin/formValidate'
  import DateRangePicker from 'vue2-daterange-picker'
  import ButtonElement from '../../components/elements/ButtonElement'
  import axios from 'axios'
  
  
  const apiUrl = process.env.VUE_APP_API_URL
  
  export default {
    name: 'GetBusinessXLS',
    components: {
      DateRangePicker,
      ButtonElement,
      PlatformSelector
    },
    mixins: [formValidate],
    data() {
      return {
        locale: process.env.VUE_APP_LOCALE,
        lang: this.$i18n._vm.locale,
        loading: false,
        isShow: false,
        fileUrl: '',
        respErr: '',
        dataError: false,
        currentDate: '',
        hours: new Date().getHours(),
        minutes: new Date().getMinutes(),
        seconds: new Date().getSeconds(),
        isDisabled: false,
        date: {
          startDate: new Date(),
          endDate: new Date()
        },
        minDate: null,
        localeRU: {
          direction: 'ltr',
          format: 'mm/dd/yyyy',
          separator: ' - ',
          daysOfWeek: ['вс', 'пн', 'вт', 'ср', 'чт', 'пт', 'сб'],
          monthNames: ['янв', 'фев', 'мар', 'апр', 'май', 'июн', 'июл', 'авг', 'сен', 'окт', 'нояб', 'дек'],
          firstDay: 1
        },
        localeEN: {
          direction: 'ltr',
          format: 'mm/dd/yyyy',
          separator: ' - ',
          daysOfWeek: ['sun', 'mon', 'tu', 'wen', 'th', 'fr', 'sat'],
          monthNames: ['jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec'],
          firstDay: 1
        },
        monthsRu: [
        {
          id: 0,
          shortName: 'янв'
        },
        {
          id: 1,
          shortName: 'фев'
        },
        {
          id: 2,
          shortName: 'мар'
        },
        {
          id: 3,
          shortName: 'апр'
        },
        {
          id: 4,
          shortName: 'май'
        },
        {
          id: 5,
          shortName: 'июн'
        },
        {
          id: 6,
          shortName: 'июл'
        },
        {
          id: 7,
          shortName: 'авг'
        },
        {
          id: 8,
          shortName: 'сен'
        },
        {
          id: 9,
          shortName: 'окт'
        },
        {
          id: 10,
          shortName: 'нояб'
        },
        {
          id: 11,
          shortName: 'дек'
        }
      ],
      monthsEn: [
        {
          id: 0,
          shortName: 'jan'
        },
        {
          id: 1,
          shortName: 'feb'
        },
        {
          id: 2,
          shortName: 'mar'
        },
        {
          id: 3,
          shortName: 'apr'
        },
        {
          id: 4,
          shortName: 'may'
        },
        {
          id: 5,
          shortName: 'jun'
        },
        {
          id: 6,
          shortName: 'jul'
        },
        {
          id: 7,
          shortName: 'aug'
        },
        {
          id: 8,
          shortName: 'sep'
        },
        {
          id: 9,
          shortName: 'oct'
        },
        {
          id: 10,
          shortName: 'nov'
        },
        {
          id: 11,
          shortName: 'dec'
        }
      ],
        activePeriod: 'day',
        platformTypes: {
        'ru':[
          {
            id: 0,
            name: "Все",
          },
          {
            id: 1,
            name: "VK Реклама",
          },
          {
            id: 2,
            name:  "Google Ads"
          },
          {
            id: 3,
            name: "Яндекс Директ"
          },
          {
            id: 4,
            name:'myTarget'
          },
          {
            id: 5,
            name: 'Telegram Ads'
          },
          {
            id: 6,
            name: 'ВКонтакте'
          }
        ],
        'en': [
          {
            id: 0,
            name: "All",
          },
          {
            id: 1,
            name: "VK Ads",
          },
          {
            id: 2,
            name:  "Google Ads"
          },
          {
            id: 3,
            name:  "TikTok"
          },
          {
            id: 4,
            name: "Yandex.Direct"
          },
          {
            id: 5,
            name:'Meta'
          }
        ]
      },
      // platforms: [
      //   "all",
      //   "vkads",
      //   "google",
      //   "tiktok",
      //   "yandex",
      //   "facebook",
      //   "mytarget",
      //   "telegram",
      //   "vkontakte"   
      // ],
      platforms: {
        'ru': [
        "all",
        "vkads",
        "google",
        "yandex",
        "mytarget",
        "telegram",
        "vkontakte" 
        ],
        'usa': [
        "all",
        "vkads",
        "google",
        "tiktok",
        "yandex",
        "facebook" 
        ]
      },
      service:"all"
      }
      
    },
    computed: {
      start_at() {
        return this.fixDateFormatNumbers(this.date.startDate)
      },
      end_at() {
        return this.fixDateFormatNumbers(this.date.endDate)
      },
      baActivePlatform() {
        return this.$store.state.Dialog.baActivePlatform
      },
      dialogTitle() {
        return this.$store.state.Dialog.dialogTitle
      }
    },
  
    created() {
      this.currentDate = this.fixDateFormatNumbers(new Date())
    },
    methods: {
      closeModal() {
        this.$store.dispatch('Dialog/closeDialog')
        this.$store.commit('Dialog/setData', { label: 'baActivePlatform', data: null })
        this.$store.commit('Dialog/setData', { label: 'dialogTitle', data: '' })
      },
  
      fixDate(start, end) {
      if (start && end) {
        let str = ''
        const startDay = start.getDate()
        const endDay = end.getDate()

        const startMonth = start.getMonth()
        const endMonth = end.getMonth()

        let nameStartMonth, nameEndMonth
        if (this.$i18n._vm.locale === 'ru') {
          nameStartMonth = this.monthsRu.find(el => el.id === startMonth).shortName
          nameEndMonth = this.monthsRu.find(el => el.id === endMonth).shortName
        } else if (this.$i18n._vm.locale === 'en') {
          nameStartMonth = this.monthsEn.find(el => el.id === startMonth).shortName
          nameEndMonth = this.monthsEn.find(el => el.id === endMonth).shortName
        }

        // const nameStartMonth = this.months.find(el => el.id === startMonth).shortName
        // const nameEndMonth = this.months.find(el => el.id === endMonth).shortName

        if (startDay === endDay && startMonth === endMonth) {
          str = startDay + ' ' + nameStartMonth
        } else if (startMonth === endMonth) {
          str = startDay + '-' + endDay + ' ' + nameStartMonth
        } else {
          str = startDay + ' ' + nameStartMonth + ' - ' + endDay + ' ' + nameEndMonth
        }

        return str
      }
    },
    fixDateFormatNumbers(fixedDate) {
      if (fixedDate) {
        let date = new Date(fixedDate)
        let str = ''
        const startDay = String(date.getDate()).padStart(2, '0')
        const startMonth = String(date.getMonth() + 1).padStart(2, '0');
        const startYear = date.getFullYear()
        str = startYear + '-' + startMonth + '-' + startDay
        return str
      }
    },
    getUsersList() {
        const token = localStorage.getItem('token')
        const params = {
          start_at: this.fixDateFormatNumbers(this.date.startDate),
          end_at: `${this.fixDateFormatNumbers(this.date.endDate)} ${'23:59:59'}`
        }
        if (this.baActivePlatform)  params.service = this.baActivePlatform.platform
        if(this.baActivePlatform.google_upload) {
          axios({ url: `${apiUrl}/admin/pa/excel`, method: 'GET', headers: { 'Authorization': `Bearer ${token} ` }, params })
          .then(resp => {
            this.dataError = false
            this.fileUrl = resp.data.data
            this.isDisabled = true
  
            const link = document.createElement('a');
            link.href = resp.data.data;
            link.download = 'build.xlsx';
            document.body.appendChild(link);
            link.click();
            this.closeModal()
            this.$notify({
              message: this.$t('notify.document_loaded'),
              type: 'success'
            });
          })
          .catch(err => {
            console.log(err.response)
            this.dataError = true
            this.respErr = err.response.statusText
            this.isDisabled = false
          })
        } else {
          axios({ url: `${apiUrl}/admin/payments/excel`, method: 'GET', headers: { 'Authorization': `Bearer ${token} ` }, params })
          .then(resp => {
            this.dataError = false
            this.fileUrl = resp.data.data
            this.isDisabled = true
  
            const link = document.createElement('a');
            link.href = resp.data.data;
            link.download = 'build.xlsx';
            document.body.appendChild(link);
            link.click();
            this.closeModal()
            this.$notify({
              message: this.$t('notify.document_loaded'),
              type: 'success'
            });
          })
          .catch(err => {
            console.log(err.response)
            this.dataError = true
            this.respErr = err.response.statusText
            this.isDisabled = false
          })
        }
  
    },
    getAllUsersList() {
        const token = localStorage.getItem('token')
        const params = {
          start_at: this.fixDateFormatNumbers(this.date.startDate),
          end_at: `${this.fixDateFormatNumbers(this.date.endDate)} ${'23:59:59'}`
        }
        if(this.baActivePlatform && (this.baActivePlatform.payload === 'aar' || this.baActivePlatform.payload === 'saa'  || this.baActivePlatform.payload === 'ad')) params.service = this.service
        if(this.baActivePlatform && this.baActivePlatform.payload === 'first_billing_ba') {
          axios({ url: `${apiUrl}/admin/first-billing/excel`, method: 'GET', headers: { 'Authorization': `Bearer ${token} ` }, params })
            .then(resp => {
              this.dataError = false
              this.fileUrl = resp.data.data
              this.isDisabled = true
              const link = document.createElement('a');
              link.href = resp.data.data;
              link.download = 'build.xlsx';
              document.body.appendChild(link);
              link.click();
              this.closeModal()
              this.$notify({
                message: this.$t('notify.document_loaded'),
                type: 'success'
              });
            })
            .catch(err => {
              console.log(err.response)
              this.dataError = true
              this.respErr = err.response.statusText
              this.isDisabled = false
            })
        } else if(this.baActivePlatform && this.baActivePlatform.payload === 'bm_new_clients') {
          axios({ url: `${apiUrl}/admin/vm-new-clients/excel`, method: 'GET', headers: { 'Authorization': `Bearer ${token} ` }, params })
            .then(resp => {
              this.dataError = false
              this.fileUrl = resp.data.data
              this.isDisabled = true
              const link = document.createElement('a');
              link.href = resp.data.data;
              link.download = 'build.xlsx';
              document.body.appendChild(link);
              link.click();
              this.closeModal()
              this.$notify({
                message: this.$t('notify.document_loaded'),
                type: 'success'
              });
            })
            .catch(err => {
              console.log(err.response)
              this.dataError = true
              this.respErr = err.response.statusText
              this.isDisabled = false
            })
        } else if (this.baActivePlatform && this.baActivePlatform.payload === 'ad') {
          axios({ url: `${apiUrl}/admin/pa/excel`, method: 'GET', headers: { 'Authorization': `Bearer ${token} ` }, params })
          .then(resp => {
            this.dataError = false
            this.fileUrl = resp.data.data
            this.isDisabled = true
            const link = document.createElement('a');
            link.href = resp.data.data;
            link.download = 'build.xlsx';
            document.body.appendChild(link);
            link.click();
            this.closeModal()
            this.$notify({
              message: this.$t('notify.document_loaded'),
              type: 'success'
            });
          })
          .catch(err => {
            console.log(err.response)
            this.dataError = true
            this.respErr = err.response.statusText
            this.isDisabled = false
          })
        } else if(this.baActivePlatform && this.baActivePlatform.payload === 'saa') {
          axios({ url: `${apiUrl}/admin/spents/excel`, method: 'GET', headers: { 'Authorization': `Bearer ${token} ` }, params })
          .then(resp => {
            this.dataError = false
            this.fileUrl = resp.data.data
            this.isDisabled = true
            const link = document.createElement('a');
            link.href = resp.data.data;
            link.download = 'build.xlsx';
            document.body.appendChild(link);
            link.click();
            this.closeModal()
            this.$notify({
              message: this.$t('notify.document_loaded'),
              type: 'success'
            });
          })
          .catch(err => {
            console.log(err.response)
            this.dataError = true
            this.respErr = err.response.statusText
            this.isDisabled = false
          })
        } else {
          axios({ url: `${apiUrl}/admin/payments/excel`, method: 'GET', headers: { 'Authorization': `Bearer ${token} ` }, params })
          .then(resp => {
            this.dataError = false
            this.fileUrl = resp.data.data
            this.isDisabled = true
  
            const link = document.createElement('a');
            link.href = resp.data.data;
            link.download = 'build.xlsx';
            document.body.appendChild(link);
            link.click();
            this.closeModal()
            this.$notify({
              message: this.$t('notify.document_loaded'),
              type: 'success'
            });
          })
          .catch(err => {
            console.log(err.response)
            this.dataError = true
            this.respErr = err.response.statusText
            this.isDisabled = false
          })
        }
    },
    getNewData(val) {
      if(this.locale ==='RU')this.service = this.platforms.ru[val]
      if(this.locale ==='USA')this.service = this.platforms.usa[val]
    },
  },
 

  }
  </script>
    
  <style lang="scss">
  @import "@/assets/styles/helpers/variables";
  @import "@/assets/styles/helpers/mixin";
  
  @keyframes settingsRotate {
    from {
      transform: rotate(0);
    }
  
    to {
      transform: rotate(360deg);
    }
  }
  
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity .3s;
  }
  
  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }
  
  .error {
    color: red;
  }
  
  .save-users-data {
    border: 1px solid #d8d9da;
    font-weight: 700;
    font-size: 1.6rem;
    line-height: 1.9rem;
    border-radius: 4px;
    height: 3.5rem;
    min-width: 12.5rem;
    text-align: center;
    padding-top: 7px;
    margin-right: 1rem;
  }
  
  // .save-users-data {
  //   font-weight: 700;
  //   font-size: 1.6rem;
  //   line-height: 1.9rem;
  //   color: #fff;
  //   border-radius: 4px;
  //   background: #395FE4;
  //   border: 1.5px solid #395FE4;
  //   height: 3.5rem;
  //   min-width: 12.5rem;
  //   transition: 0.3s ease-out;
  //   padding-top: 7px;
  //   text-align: center;
  //   margin-left: auto;
  //   &:hover {
  //     background: #2D52D2;
  //   }
  // }
  
  
  .dialog-add-account {
    &__data-picker {
      display: flex;
      justify-content: space-between;
    }
  
    &__data-title {
      font-weight: 700;
    }
  
    &__button-wrapper {
      display: flex;
      margin-top: 3rem;
      align-items: center;
  
      .button {
        margin-left: auto;
      }
    }
  }
  </style>
    